/* eslint-disable no-underscore-dangle */
import cn from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import { FormattedNumber } from 'react-intl';
import { getTaxonomyLevelIterator } from 'utils/utils';

const DEFAULT_PORTFOLIO_PERCENTAGE = 1;

const buildCollapsibleMap = (data, depth = 0, expandRegions = false, expandAll = false) =>
  Object.entries(data)
    .filter(([k]) => !k.startsWith('__'))
    .reduce((acum, [key, level]) => {
      if (_.isPlainObject(level))
        return {
          ...acum,
          [level.__id ?? key]: expandAll || (key !== 'Other' && key !== 'Cash' && expandRegions),
          ...buildCollapsibleMap(level, depth + 1, expandRegions, expandAll)
        };
      return { ...acum };
    }, {});

const PositionRow = ({
  collapsed,
  color,
  depth,
  disableExpand,
  hiddenVal,
  icon,
  label,
  lastLevel,
  level,
  showPercent,
  toggleRow,
  total,
  withParentIcon
}) => {
  const value = level.__value;

  let rowStyle = {
    borderLeft: `10px solid ${color}`,
    paddingLeft: (depth - 1) * 15 + 25 + (withParentIcon ? 24 : 0)
  };
  if (icon) rowStyle = { ...rowStyle, display: 'flex', alignItems: 'center', gap: 5 };

  const toggle = () => {
    if (toggleRow && !disableExpand && !lastLevel) toggleRow(level.__id);
  };

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div className={`row row-content row-level-${depth}`} onClick={toggle}>
      <div className="col-sm-7 text-left col-name" style={rowStyle}>
        {icon && <img src={icon} alt={label} />}
        {label}
      </div>
      <div className={`col-sm-${showPercent ? 2 : 4} col-value`}>
        {hiddenVal || <FormattedNumber value={value} format="currency" />}
      </div>
      {showPercent && (
        <div className={`col-sm-${disableExpand ? '3' : '2'} col-percentage`}>
          {!lastLevel && (
            <FormattedNumber value={value / total} format="percent" maximumFractionDigits={2} />
          )}
        </div>
      )}
      {toggleRow && !disableExpand && !lastLevel && (
        <div className="col-sm-1 text-sm-right col-arrow">
          {!lastLevel && <i className={cn('icon-arrow', { turned: collapsed })} />}
        </div>
      )}
    </div>
  );
};

PositionRow.propTypes = {
  collapsed: PropTypes.bool,
  color: PropTypes.string.isRequired,
  depth: PropTypes.number.isRequired,
  disableExpand: PropTypes.bool,
  hiddenVal: PropTypes.bool,
  icon: PropTypes.string,
  label: PropTypes.string.isRequired,
  lastLevel: PropTypes.bool,
  level: PropTypes.object.isRequired,
  showPercent: PropTypes.bool,
  toggleRow: PropTypes.func,
  total: PropTypes.number.isRequired,
  withParentIcon: PropTypes.bool
};

PositionRow.defaultProps = {
  collapsed: false,
  disableExpand: false,
  hiddenVal: false,
  icon: null,
  lastLevel: false,
  showPercent: false,
  toggleRow: null,
  withParentIcon: false
};

const CollapsibleTable = ({
  bottomNameLabel,
  data,
  disableExpand,
  expandAll,
  expandAssetClassification,
  expandAssetDetails,
  expandRegions,
  headingLabels,
  hiddenVal,
  hideLeaves,
  notAvailableRowLabel,
  showBottomSection,
  showHeadingRow,
  showPercent,
  showNotAvailableSection
}) => {
  const [collapsibleMap, setCollapsibleMap] = useState({});

  useEffect(() => {
    setCollapsibleMap(buildCollapsibleMap(data, expandRegions, expandAll));
  }, [JSON.stringify(data), expandRegions, expandAll]);

  const toggleRow = id => {
    setCollapsibleMap({
      ...collapsibleMap,
      [id]: !collapsibleMap[id]
    });
  };

  const totalValue = data.__value;
  const totalValuePercentage = data.__value_percentage ?? 1;
  const isPortfolioRelative = Math.floor(totalValuePercentage) !== DEFAULT_PORTFOLIO_PERCENTAGE;
  const rowProps = { total: totalValue, showPercent, disableExpand, hiddenVal };

  return (
    <div className="collapsible-table">
      {showHeadingRow && (
        <div className="row row-helper">
          <div className="col-sm-7 name-col">{headingLabels.name}</div>
          <div className={`col-sm-${showPercent ? 2 : 4} value-col`}>
            {hiddenVal || headingLabels.value}
          </div>
          {showPercent && <div className="col-sm-2 percent-col">{headingLabels.percent}</div>}
          <div className="col-sm-1" />
        </div>
      )}

      <div className="row-content-block">
        {getTaxonomyLevelIterator(data).map(([l1Key, l1], l1Idx) => (
          <Fragment key={`l1-${l1.__id ?? l1Idx}`}>
            <PositionRow
              collapsed={expandAssetClassification && collapsibleMap[l1.__id]}
              color={l1.__color}
              depth={1}
              icon={l1.__icon}
              label={l1Key}
              level={l1}
              toggleRow={expandAssetClassification ? toggleRow : null}
              {...rowProps}
            />

            {expandAssetClassification &&
              !collapsibleMap[l1.__id] &&
              getTaxonomyLevelIterator(l1).map(([l2Key, l2], l2Idx) => (
                <Fragment key={`l2-${l2.__id ?? `${l1Idx}-${l2Idx}`}`}>
                  <PositionRow
                    collapsed={expandAssetDetails && collapsibleMap[l2.__id]}
                    color={l1.__color}
                    depth={2}
                    label={l2Key}
                    level={l2}
                    toggleRow={expandAssetDetails ? toggleRow : null}
                    withParentIcon={!!l1.__icon}
                    {...rowProps}
                  />

                  {expandAssetDetails &&
                    !collapsibleMap[l2.__id] &&
                    !hideLeaves &&
                    getTaxonomyLevelIterator(l2).map(([l3Key, l3], l3Idx) => (
                      <Fragment key={`l3-${l3.__id ?? `${l1Idx}-${l2Idx}-${l3Idx}`}`}>
                        <PositionRow
                          collapsed={collapsibleMap[l3.__id]}
                          color={l1.__color}
                          depth={3}
                          label={l3Key}
                          lastLevel
                          level={l3}
                          withParentIcon={!!l1.__icon}
                          {...rowProps}
                        />
                      </Fragment>
                    ))}
                </Fragment>
              ))}
          </Fragment>
        ))}
      </div>

      {showBottomSection && (
        <div className="row row-helper">
          <div className="col-sm-7 name-col text-left">{bottomNameLabel}</div>
          <div className={`col-sm-${showPercent ? 2 : 4}  value-col`}>
            {hiddenVal || (
              <FormattedNumber
                value={isPortfolioRelative ? totalValue * totalValuePercentage : totalValue}
                format="currency"
              />
            )}
          </div>
          {showPercent && (
            <div className={`col-sm-${disableExpand ? '3' : '2'} percent-col`}>
              <FormattedNumber
                format="percent"
                maximumFractionDigits={2}
                value={totalValuePercentage}
              />
            </div>
          )}
          {!disableExpand && <div className="col-sm-1" />}
        </div>
      )}

      {showBottomSection && isPortfolioRelative && showNotAvailableSection && (
        <div className="row row-helper">
          <div className="col-sm-7 name-col text-left">{notAvailableRowLabel}</div>
          <div className={`col-sm-${showPercent ? 2 : 4}  value-col`}>
            {hiddenVal || (
              <FormattedNumber value={totalValue * (1 - totalValuePercentage)} format="currency" />
            )}
          </div>
          {showPercent && (
            <div className={`col-sm-${disableExpand ? '3' : '2'} percent-col`}>
              <FormattedNumber
                format="percent"
                maximumFractionDigits={2}
                value={1 - totalValuePercentage}
              />
            </div>
          )}
          {!disableExpand && <div className="col-sm-1" />}
        </div>
      )}
    </div>
  );
};

CollapsibleTable.propTypes = {
  bottomNameLabel: PropTypes.string,
  data: PropTypes.object.isRequired,
  disableExpand: PropTypes.bool,
  expandAssetClassification: PropTypes.bool,
  expandAssetDetails: PropTypes.bool,
  expandAll: PropTypes.bool,
  expandRegions: PropTypes.bool,
  headingLabels: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    percent: PropTypes.string.isRequired
  }),
  hiddenVal: PropTypes.bool,
  hideLeaves: PropTypes.bool,
  notAvailableRowLabel: PropTypes.string,
  showBottomSection: PropTypes.bool,
  showHeadingRow: PropTypes.bool,
  showPercent: PropTypes.bool,
  showNotAvailableSection: PropTypes.bool
};

CollapsibleTable.defaultProps = {
  bottomNameLabel: '',
  disableExpand: false,
  expandAssetClassification: true,
  expandAssetDetails: false,
  expandAll: false,
  expandRegions: false,
  headingLabels: {
    name: '',
    value: '',
    percent: ''
  },
  hiddenVal: false,
  hideLeaves: false,
  notAvailableRowLabel: 'Not Reported / Not Available',
  showBottomSection: true,
  showHeadingRow: true,
  showPercent: false,
  showNotAvailableSection: true
};

export default CollapsibleTable;
