import PropTypes from 'prop-types';
import React from 'react';
import { FormattedNumber } from 'react-intl';
import './styles.scss';

const DisclaimerNote = ({ onlyEquity, totalAssets, totalSecurities, isTopHoldings }) => {
  if (isTopHoldings) {
    return (
      <div className="disclaimer-note">
        <p>
          The top 10 holdings listed above represent{' '}
          <FormattedNumber value={totalSecurities / totalAssets} format="percent" /> of the total
          portfolio exposure.
        </p>
      </div>
    );
  }

  if (Math.floor(totalSecurities) === Math.floor(totalAssets)) return null;
  return (
    <div className="disclaimer-note">
      <p>
        For this portfolio, data from our external providers covers{' '}
        <FormattedNumber value={totalSecurities / totalAssets} format="percent" /> of the portfolio.
        The remaining <FormattedNumber value={1 - totalSecurities / totalAssets} format="percent" />{' '}
        is either not reported or unavailable from our providers.{' '}
        {onlyEquity && '(Includes only equity stocks and equity-based funds)'}
      </p>
    </div>
  );
};

DisclaimerNote.propTypes = {
  onlyEquity: PropTypes.bool,
  totalAssets: PropTypes.number.isRequired,
  totalSecurities: PropTypes.number.isRequired,
  isTopHoldings: PropTypes.bool
};

DisclaimerNote.defaultProps = {
  onlyEquity: false,
  isTopHoldings: false
};

export default DisclaimerNote;
